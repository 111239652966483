import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';

import ResourceDetailLayout from 'components/ResourceDetailLayout';

import type { ContentfulComponentForm, ContentfulComponentHeading, ContentfulTemplateResource } from 'graphqlTypes';
import type { FC } from 'react';

interface ReportDataType {
  reportData: ContentfulTemplateResource;
  cardsHeading: ContentfulComponentHeading;
  form: ContentfulComponentForm;
}

const Page: FC<PageProps<ReportDataType>> = ({ data: { cardsHeading, reportData, form }, location }) => (
  <Layout slug={location.pathname} seo={reportData.seo}>
    <ResourceDetailLayout
      {...reportData}
      cardsHeading={cardsHeading}
      displayBreadcrumbs={reportData.seo?.displayBreadcrumbs}
      form={form}
    />
  </Layout>
);

export default Page;

export const pageQuery = graphql`
  query templateResourceQuery($slug: String!) {
    reportData: contentfulTemplateResource(slug: { eq: $slug }) {
      ...contentfulTemplateResource
    }
    cardsHeading: contentfulComponentHeading(contentful_id: { eq: "5khUk1G1PlV1Sg6UXmLiIP" }) {
      ...contentfulComponentHeading
    }
    form: contentfulComponentForm(contentful_id: { eq: "4n7LmBwc0WKJSeBRzG3APW" }) {
      ...contentfulComponentForm
    }
  }
`;
